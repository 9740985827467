<template>
  <div>
    <!-- pse search section -->
    <section id="pse-search-filter">
      <b-card
        no-body
        class="pse-search"
      >
        <b-card-body class="text-center knw">
          <!-- form -->
          <b-form
            class="pse-search-input"
            @submit.prevent="fetchData"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="searchbar"
                v-model="pseSearchQuery"
                placeholder="Search PSE Database..."
              />
              <b-input-group-append v-if="pseSearchQuery">
                <b-button
                  variant="outline-primary"
                  @click.prevent="clearSearchData"
                >
                  <feather-icon
                    icon="XIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
          <!-- form -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ pse search section -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="onShowPageChange"
            />
            <label>entries</label>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPseListTable"
        :items="pseData"
        :fields="tableColumns"
        primary-key="pse_id"
        show-empty
        small
        fixed
        empty-text="No matching records found"
      >

        <!-- Column: User -->
        <template #cell(nama_sistem_elektronik)="data">
          <p class="font-weight-bold mb-50">
            {{ data.item.nama_sistem_elektronik }}
          </p>
          <span class="small mb-50 text-truncate">
            <b-link
              :href="(data.item.alamat_website.includes('http')) ? data.item.alamat_website : 'https://' + data.item.alamat_website"
              target="_blank"
            >
              <feather-icon
                icon="ExternalLinkIcon"
                size="12"
              />
            </b-link>
            {{ data.item.alamat_website }}
          </span>
        </template>

        <template #cell(nama_pemohon)="data">
          <p class="font-weight-bold mb-50">
            {{ data.item.nama_badan_hukum }}
          </p>
          <span class="small mb-50">
            {{ data.item.nama_pemohon }}
          </span>
        </template>

        <!-- Column: Updated At -->
        <template #cell(tanggal_pengajuan)="data">
          <div class="text-nowrap">
            {{ data.item.tanggal_pengajuan | formatDate }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="viewData(data.item)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View Detail</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-pse"
      ref="modal-pse"
      title="PSE Details"
      size="lg"
      hide-footer
    >
      <b-card-text>
        <h3 class="font-weight-bold mb-1">
          {{ pseDetail.subject }}
        </h3>

        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Sistem Elektronik
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_sistem_elektronik }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Alamat Website
          </b-col>
          <b-col
            cols="7"
            class="overflow-auto"
          >
            {{ pseDetail.alamat_website }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Pemohon
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_pemohon }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Badan Hukum
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_badan_hukum }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            NIB
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nib }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor PB Umku
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_pb_umku }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor Permohonan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_permohonan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nomor TDPSE
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nomor_tdpse }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Lokalitas
          </b-col>
          <b-col cols="7">
            {{ pseDetail.lokalitas }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Jenis Permohonan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.jenis_permohonan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Kategori Layanan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.kategori_layanan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Proses Berjalan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.proses_berjalan }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Status
          </b-col>
          <b-col cols="7">
            {{ pseDetail.status }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Nama Verifikator
          </b-col>
          <b-col cols="7">
            {{ pseDetail.nama_verifikator }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Status Verifikasi
          </b-col>
          <b-col cols="7">
            {{ pseDetail.status_verifikasi }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Tanggal Pengajuan
          </b-col>
          <b-col cols="7">
            {{ pseDetail.tanggal_pengajuan | formatDate }}
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col
            cols="5"
            class="font-weight-bold"
          >
            Tanggal Terbit
          </b-col>
          <b-col cols="7">
            {{ pseDetail.tanggal_terbit | formatDate }}
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
    <!-- modal -->

  </div>
</template>

<script>
import {
  BCard, BCardBody, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BRow, BCol, BLink,
  BInputGroupAppend, BButton, BPagination, BTable, BDropdown, BDropdownItem, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BCard,
    BCardBody,
    BCardText,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BInputGroupAppend,
    BPagination,

    vSelect,
  },
  data() {
    return {
      tagData: {},
      currentPage: 1,
      perPage: 25,
      pseSearchQuery: '',
      pseDetail: {},
      pseData: [],
      dataMeta: {
        from: '',
        to: '',
        total: '',
      },
      roleFilter: '',
      prevRoleFilter: '',
    }
  },
  setup() {
    const toast = useToast()

    // Table Handlers
    const tableColumns = [
      { key: 'nama_sistem_elektronik', class: 'nama_pse' },
      { key: 'nama_pemohon' },
      { key: 'tanggal_pengajuan', label: 'Tanggal Pengajuan' },
      { key: 'status' },
      { key: 'status_verifikasi' },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]

    return {
      toast,
      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.fetchData()
    },
    onShowPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = 1
      this.perPage = params
      this.fetchData()
    },
    clearSearchData() {
      this.pseSearchQuery = ''
      this.fetchData()
    },
    viewData(data) {
      this.pseDetail = data
      this.$refs['modal-pse'].show()
    },
    fetchData() {
      document.getElementById('loading-bg').style.display = 'block'
      const params = {
        length: this.perPage,
        page: this.currentPage,
      }
      if (this.pseSearchQuery !== '') {
        params.keyword = this.pseSearchQuery
      }
      this.$http.post('/pse/search', params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.pseData = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching PSE data',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
